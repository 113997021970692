import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import GoogleMapInit from './Elements/GoogleMap/GoogleMapInit';
import CmsPlaceholder from './CmsPlaceholder';

// API key of the google map
// const GOOGLE_MAP_API_KEY = '<YOUR_GOOGLE_MAP_API_KEY>';

export const CmsMap = () => {
  const [loadPlaceholder, setLoadPlaceholder] = useState(false);
  const elementId = 'googleMapScript';
  const scriptUrl =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBeUzDq0i9YtW2UJL7umrnsZ9TouaeBz9I&callback=initMap';

  useEffect(() => {
    const existingScript = document.getElementById(elementId);
    if (typeof Cookies.get('mapPlaceholder') !== 'undefined') {
      setLoadPlaceholder(true);
    }
    if (!existingScript && loadPlaceholder) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.async = true;
      script.id = elementId;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [loadPlaceholder]);
  return !loadPlaceholder ? (
    <CmsPlaceholder element="map" setLoadPlaceholder={setLoadPlaceholder} />
  ) : (
    <GoogleMapInit />
  );
};

export default CmsMap;
