import React, { useRef, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import { I18nContext } from '@deity/falcon-i18n';
import markersMock from '../../../../../../../../../mocks/markersMocks';
import muellerLogo from '../../../../../../../../../assets/images/drugstore/mueller-map-pin.png';
import InfoWindowContent from './InfoWindowContent';

const GoogleMapInit = () => {
  const { t } = useContext(I18nContext);
  const googleMapRef = useRef(null);
  const labels = {
    street: t('widget.street'),
    city: t('widget.city'),
    module: t('widget.module')
  };

  const initGoogleMap = () => {
    // initialize the Google map
    const map = new window.google.maps.Map(googleMapRef.current, {
      center: { lat: 50.9916038, lng: 10.2136589 },
      zoom: 6
    });

    // create marker on Google map
    const createMarker = markerObj =>
      new window.google.maps.Marker({
        position: { lat: markerObj.position.lat, lng: markerObj.position.lng },
        map,
        icon: {
          url: muellerLogo,
          // set marker width and height
          scaledSize: new window.google.maps.Size(25, 25)
        },
        content: 'Title'
      });

    markersMock.markers.map(mapMarker => {
      const marker = createMarker(mapMarker);
      const infoWindow = new window.google.maps.InfoWindow({
        content: ''
      });
      marker.addListener('mouseover', () => {
        const content = ReactDOMServer.renderToString(
          <InfoWindowContent
            name={mapMarker.name}
            street={mapMarker.street}
            zip={mapMarker.zip}
            city={mapMarker.city}
            module={mapMarker.module}
            labels={labels}
          />
        );
        infoWindow.setContent(content);
        infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: false
        });
      });
      marker.addListener('mouseout', () => {
        infoWindow.close();
      });
      return undefined;
    });
  };
  window.initMap = initGoogleMap; // the map to contain all markers

  return <div ref={googleMapRef} style={{ width: '100%', height: '100vh' }} />;
};

export default GoogleMapInit;
