const markersMock = {
  // do not delete - markers are used for mueller google map!
  markers: [
    {
      id: '3772',
      zip: '65185',
      name: 'Wiesbaden',
      street: 'Kirchgasse 11-13',
      city: 'Wiesbaden',
      position: { lat: 50.07847839999999, lng: 8.238399399999935 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7149',
      zip: '6108',
      name: 'Halle',
      street: 'Große Ulrichstr. 2',
      city: 'Halle',
      position: { lat: 51.4841, lng: 11.96925120000003 },
      region: 'DE',
      opening_hours: [],
      module: '1,0m'
    },
    {
      id: '7147',
      zip: '6366',
      name: 'Köthen',
      street: 'Buttermarkt 5',
      city: 'Köthen',
      position: { lat: 51.751369, lng: 11.97567730000003 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7175',
      zip: '12683',
      name: 'Berlin Biesdorf-Center',
      street: 'Weißenhöher Str. 88',
      city: 'Berlin',
      position: { lat: 52.5063189, lng: 13.559006100000033 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3742',
      zip: '15834',
      name: 'Rangsdorf Südring-Center',
      street: 'Klein-Kienitzer-Str. 2',
      city: 'Rangsdorf',
      position: { lat: 52.2975736, lng: 13.458637199999998 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3732',
      zip: '22415',
      name: 'Hamburg Langenhorn',
      street: 'Langenhorner Markt',
      city: 'Hamburg',
      position: { lat: 53.649797, lng: 10.011899500000027 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3518',
      zip: '22926',
      name: 'Ahrensburg City Center',
      street: 'Klaus-Groth-Str. 2-4',
      city: 'Ahrensburg',
      position: { lat: 53.6750044, lng: 10.238270199999988 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1209',
      zip: '74889',
      name: 'Sinsheim',
      street: 'Bahnhofstr. 25',
      city: 'Sinsheim',
      position: { lat: 49.25177040000001, lng: 8.876269900000011 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1308',
      zip: '78532',
      name: 'Tuttlingen',
      street: 'Bahnhofstr.3/Helfereistr. 9-11',
      city: 'Tuttlingen',
      position: { lat: 47.9840291, lng: 8.817944600000033 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1506',
      zip: '78713',
      name: 'Schramberg',
      street: 'Hauptstr. 14-18',
      city: 'Schramberg',
      position: { lat: 48.2260459, lng: 8.384541200000058 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7094',
      zip: '82205',
      name: 'Gilching Dornierstraße',
      street: 'Dornierstr. 2 c',
      city: 'Gilching',
      position: { lat: 48.09405, lng: 11.312729999999988 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1272',
      zip: '82319',
      name: 'Starnberg',
      street: 'Josef-Jägerhuber-Str. 7',
      city: 'Starnberg',
      position: { lat: 48.0010102, lng: 11.34421670000006 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3576',
      zip: '84032',
      name: 'Altdorf',
      street: 'Am Aicher Feld 14',
      city: 'Altdorf',
      position: { lat: 48.5662771, lng: 12.100192200000038 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1491',
      zip: '84056',
      name: 'Rottenburg/Laaber',
      street: 'Landshuter Str. 86',
      city: 'Rottenburg/Laaber',
      position: { lat: 48.691927, lng: 12.027033399999937 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1454',
      zip: '84130',
      name: 'Dingolfing',
      street: 'Amperstraße 2',
      city: 'Dingolfing',
      position: { lat: 48.6393546, lng: 12.482524399999988 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1159',
      zip: '86150',
      name: 'Augsburg Karolinenstraße',
      street: 'Karolinenstr. 12 - 14',
      city: 'Augsburg',
      position: { lat: 48.3699743, lng: 10.898340500000018 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1507',
      zip: '86199',
      name: 'Augsburg Göggingen',
      street: 'Eichleitnerstr. 3',
      city: 'Augsburg',
      position: { lat: 48.3460724, lng: 10.885276299999987 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1504',
      zip: '86316',
      name: 'Friedberg Lechhauser Straße',
      street: 'Lechhauser Str. 44',
      city: 'Friedberg',
      position: { lat: 48.36261409999999, lng: 10.956540099999984 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1476',
      zip: '86391',
      name: 'Stadtbergen',
      street: 'Benzstraße 8',
      city: 'Stadtbergen',
      position: { lat: 48.3763341, lng: 10.848196199999961 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7099',
      zip: '88348',
      name: 'Bad Saulgau Moosheimer Straße',
      street: 'Moosheimer Str. 29',
      city: 'Bad Saulgau',
      position: { lat: 48.0214439, lng: 9.49551329999997 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3697',
      zip: '88512',
      name: 'Mengen',
      street: 'Reiserstr. 6-8',
      city: 'Mengen',
      position: { lat: 48.049618, lng: 9.332870400000047 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3340',
      zip: '88662',
      name: 'Überlingen Abigstraße',
      street: 'Abigstr. 1',
      city: 'Überlingen',
      position: { lat: 47.775175, lng: 9.187578099999996 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1058',
      zip: '90402',
      name: 'Nürnberg Königstrasse',
      street: 'Königstr. 26',
      city: 'Nürnberg',
      position: { lat: 49.450656, lng: 11.077525899999955 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7093',
      zip: '90439',
      name: 'Nürnberg Schweinau',
      street: 'Geisseestr. 42-44',
      city: 'Nürnberg',
      position: { lat: 49.4355894, lng: 11.0433223 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1368',
      zip: '90473',
      name: 'Nürnberg Franken-Center',
      street: 'Glogauer Str. 36-38',
      city: 'Nürnberg',
      position: { lat: 49.4042161, lng: 11.13276529999996 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1392',
      zip: '90491',
      name: 'Nürnberg Mercado Center',
      street: 'Äußere Bayreuther Str. 80',
      city: 'Nürnberg',
      position: { lat: 49.4696599, lng: 11.101406399999973 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3688',
      zip: '97215',
      name: 'Uffenheim',
      street: 'Nürnberger Str. 5',
      city: 'Uffenheim',
      position: { lat: 49.5419185, lng: 10.238765599999965 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1508',
      zip: '97318',
      name: 'Kitzingen',
      street: 'Siegfried-Wilke-Str. 4',
      city: 'Kitzingen',
      position: { lat: 49.7400555, lng: 10.149631699999986 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3114',
      zip: '97332',
      name: 'Volkach',
      street: 'Am alten Bahnhof 4',
      city: 'Volkach',
      position: { lat: 49.8624435, lng: 10.225350899999967 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '2121',
      zip: '27283',
      name: 'Verden / Aller',
      street: 'Grosse Str. 88',
      city: 'Verden / Aller',
      position: { lat: 52.9198278, lng: 9.228448299999968 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3521',
      zip: '28277',
      name: 'Bremen Kattenturmer-Heerstraße',
      street: 'Kattenturmer-Heerstr. 142',
      city: 'Bremen',
      position: { lat: 53.0407117, lng: 8.81594849999999 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1419',
      zip: '47623',
      name: 'Kevelaer',
      street: 'Hauptstr. 36',
      city: 'Kevelaer',
      position: { lat: 51.5827616, lng: 6.2461349000000155 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3716',
      zip: '74336',
      name: 'Brackenheim',
      street: 'Hermann-Bauer-Straße 2/1',
      city: 'Brackenheim',
      position: { lat: 49.0747949, lng: 9.063927599999943 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3676',
      zip: '74906',
      name: 'Bad Rappenau',
      street: 'Raiffeisenstr. 23',
      city: 'Bad Rappenau',
      position: { lat: 49.23617489999999, lng: 9.09810630000004 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3558',
      zip: '78166',
      name: 'Donaueschingen - Am Karlsgarten',
      street: 'Am Karlsgarten 1',
      city: 'Donaueschingen',
      position: { lat: 47.949256, lng: 8.498089499999992 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1191',
      zip: '82377',
      name: 'Penzberg',
      street: 'Karlstr. 28',
      city: 'Penzberg',
      position: { lat: 47.7523565, lng: 11.375659799999994 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3328',
      zip: '82380',
      name: 'Peißenberg',
      street: 'Am Holzgarten 8',
      city: 'Peißenberg',
      position: { lat: 47.78994369999999, lng: 11.053984799999967 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1276',
      zip: '82467',
      name: 'Garmisch-Partenkirchen',
      street: 'Von Brug Str. 14',
      city: 'Garmisch Partenkirchen',
      position: { lat: 47.4950067, lng: 11.095508999999993 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3681',
      zip: '84307',
      name: 'Eggenfelden',
      street: 'Schellenbruckplatz 23',
      city: 'Eggenfelden',
      position: { lat: 48.4013778, lng: 12.752614200000039 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3236',
      zip: '84416',
      name: 'Taufkirchen a.d. Vils',
      street: 'Landshuter Str. 44',
      city: 'Taufkirchen a. d. Vils',
      position: { lat: 48.3523375, lng: 12.128674000000046 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3515',
      zip: '86415',
      name: 'Mering',
      street: 'Ohmstr. 2-6',
      city: 'Mering',
      position: { lat: 48.2751455, lng: 10.976875500000006 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3112',
      zip: '86529',
      name: 'Schrobenhausen',
      street: 'Pöttmeser Str. 6b',
      city: 'Schrobenhausen',
      position: { lat: 48.5634289, lng: 11.256686800000011 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3699',
      zip: '86609',
      name: 'Donauwörth',
      street: 'Dillinger Strasse 22',
      city: 'Donauwörth',
      position: { lat: 48.7162166, lng: 10.769314099999974 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3731',
      zip: '86720',
      name: 'Nördlingen',
      street: 'Löpsinger Straße 10-12',
      city: 'Nördlingen',
      position: { lat: 48.8524535, lng: 10.490319499999941 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3113',
      zip: '89075',
      name: 'Ulm Böfingen',
      street: 'Haslacher Weg 51',
      city: 'Ulm',
      position: { lat: 48.4303977, lng: 10.018171299999949 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7198',
      zip: '89129',
      name: 'Langenau',
      street: 'Benzstraße 7',
      city: 'Langenau',
      position: { lat: 48.4990347, lng: 10.134214100000008 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1350',
      zip: '91074',
      name: 'Herzogenaurach',
      street: 'Hauptstr. 17',
      city: 'Herzogenaurach',
      position: { lat: 49.5681496, lng: 10.882585500000005 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7173',
      zip: '91301',
      name: 'Forchheim',
      street: 'Hauptstr. 51',
      city: 'Forchheim',
      position: { lat: 49.7188526, lng: 11.059180999999967 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1499',
      zip: '91325',
      name: 'Adelsdorf',
      street: 'Hochstr. 19',
      city: 'Adelsdorf',
      position: { lat: 49.7026265, lng: 10.882231600000068 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3663',
      zip: '91353',
      name: 'Hausen',
      street: 'Pilatus Campus 4',
      city: 'Hausen',
      position: { lat: 49.7016967, lng: 11.043836499999998 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1125',
      zip: '94315',
      name: 'Straubing Ludwigsplatz',
      street: 'Ludwigsplatz 14/16',
      city: 'Straubing',
      position: { lat: 48.8815909, lng: 12.570393200000012 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7095',
      zip: '94327',
      name: 'Bogen',
      street: 'Petersgewanne 4',
      city: 'Bogen',
      position: { lat: 48.9196222, lng: 12.671384799999942 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1147',
      zip: '97421',
      name: 'Schweinfurt',
      street: 'Spitalstr. 9',
      city: 'Schweinfurt',
      position: { lat: 50.04414879999999, lng: 10.233947199999989 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3034',
      zip: '97424',
      name: 'Niederwerrn am Hainig',
      street: 'Gretel-Baumbach-Str. 10',
      city: 'Schweinfurt',
      position: { lat: 50.0599723, lng: 10.19293259999995 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7196',
      zip: '97440',
      name: 'Werneck',
      street: 'Am Eschenbach 6',
      city: 'Werneck',
      position: { lat: 50.00413289999999, lng: 10.115276699999981 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7092',
      zip: '97526',
      name: 'Sennfeld',
      street: 'Schweinfurter Str. 3c',
      city: 'Sennfeld',
      position: { lat: 50.0387703, lng: 10.245928499999991 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3620',
      zip: '26169',
      name: 'Friesoythe',
      street: 'Am Hafen 5',
      city: 'Friesoythe',
      position: { lat: 53.0186992, lng: 7.8545477999999775 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '2124',
      zip: '26441',
      name: 'Jever',
      street: 'Mühlenstr. 72',
      city: 'Jever',
      position: { lat: 53.56167319999999, lng: 7.91289340000003 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3666',
      zip: '49074',
      name: 'Osnabrück',
      street: 'Kamp Promenade',
      city: 'Osnabrück',
      position: { lat: 52.2735046, lng: 8.046602500000063 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1466',
      zip: '71522',
      name: 'Backnang',
      street: 'Grabenstr. 15',
      city: 'Backnang',
      position: { lat: 48.9475967, lng: 9.428718200000048 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1232',
      zip: '73054',
      name: 'Eislingen',
      street: 'Hauptstr. 56-58',
      city: 'undefined',
      position: { lat: 48.6993121, lng: 9.707349399999998 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1465',
      zip: '73072',
      name: 'Donzdorf',
      street: 'Mozartstr. 35',
      city: 'Donzdorf',
      position: { lat: 48.6851515, lng: 9.796541100000013 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1420',
      zip: '73525',
      name: 'Schwäbisch Gmünd City Center',
      street: 'Kalter Markt 18',
      city: 'Schwäbisch Gmünd',
      position: { lat: 48.8011111, lng: 9.800083299999983 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1073',
      zip: '77855',
      name: 'Achern',
      street: 'Hauptstr. 55',
      city: 'Achern',
      position: { lat: 48.6286421, lng: 8.073495800000046 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7168',
      zip: '77933',
      name: 'Lahr',
      street: 'Schlossplatz 18',
      city: 'Lahr',
      position: { lat: 48.3393965, lng: 7.872036200000025 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1500',
      zip: '82140',
      name: 'Olching',
      street: 'Feursstr. 56',
      city: 'Olching',
      position: { lat: 48.2157929, lng: 11.347469700000033 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3611',
      zip: '82356',
      name: 'Freising',
      street: 'Weinmiller-Str. 5',
      city: 'Freising',
      position: { lat: 48.4102305, lng: 11.748320000000035 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3547',
      zip: '85356',
      name: 'München Airport Center',
      street: 'Ebene 03',
      city: 'München Flughafen',
      position: { lat: 48.3536621, lng: 11.775027899999941 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1402',
      zip: '85435',
      name: 'Erding',
      street: 'Friedrich-Fischer-Str. 4',
      city: 'Erding',
      position: { lat: 48.3059301, lng: 11.906391699999972 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3601',
      zip: '85560',
      name: 'Ebersberg',
      street: 'Altstadtpassage 6 + 7',
      city: 'Ebersberg',
      position: { lat: 48.0769759, lng: 11.969924200000037 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3591',
      zip: '85570',
      name: 'Markt Schwaben',
      street: 'Erdinger Str. 19',
      city: 'Markt Schwaben',
      position: { lat: 48.1928877, lng: 11.871111299999939 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3630',
      zip: '85716',
      name: 'Unterschleißheim',
      street: 'Landshuter Str. 16',
      city: 'Unterschleißheim',
      position: { lat: 48.2812357, lng: 11.564702500000067 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1032',
      zip: '88212',
      name: 'Ravensburg Marktstraße',
      street: 'Marktstr. 1 + 3',
      city: 'Ravensburg',
      position: { lat: 47.78191, lng: 9.614530000000059 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1251',
      zip: '88400',
      name: 'Biberach',
      street: 'Marktplatz 26',
      city: 'Biberach',
      position: { lat: 48.0978258, lng: 9.787137799999982 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3552',
      zip: '88447',
      name: 'Warthausen',
      street: 'Jahnstr. 2',
      city: 'Warthausen',
      position: { lat: 48.1301694, lng: 9.800983299999984 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1470',
      zip: '91126',
      name: 'Schwabach Rotherstraße',
      street: 'Alte Rotherstr. 1-3',
      city: 'Schwabach',
      position: { lat: 49.3235779, lng: 11.037433899999996 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7167',
      zip: '91154',
      name: 'Roth',
      street: 'Hauptstr. 17 - 19',
      city: 'Roth',
      position: { lat: 49.2461047, lng: 11.090147099999967 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1380',
      zip: '91217',
      name: 'Hersbruck',
      street: 'Prager Str. 9',
      city: 'Hersbruck',
      position: { lat: 49.50915620000001, lng: 11.429921599999943 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3626',
      zip: '91257',
      name: 'Pegnitz',
      street: 'Nürnberger Straße 20 d',
      city: 'Pegnitz',
      position: { lat: 49.7518578, lng: 11.538646400000061 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3577',
      zip: '94086',
      name: 'Bad Griesbach',
      street: 'Weghofstr. 4',
      city: 'Bad Griesbach',
      position: { lat: 48.4406399, lng: 13.188215200000059 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1065',
      zip: '94469',
      name: 'Deggendorf',
      street: 'Michael-Fischer-Platz 3-5',
      city: 'Deggendorf',
      position: { lat: 48.8313168, lng: 12.962564499999985 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3110',
      zip: '94481',
      name: 'Grafenau',
      street: 'Bahnhofplatz 5c',
      city: 'Grafenau',
      position: { lat: 48.8556146, lng: 13.393986600000062 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1374',
      zip: '94486',
      name: 'Osterhofen',
      street: 'Stadtplatz 16',
      city: 'Osterhofen',
      position: { lat: 48.70244899999999, lng: 13.020424700000035 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3613',
      zip: '97892',
      name: 'Kreuzwertheim',
      street: 'Lengfurterstr. 47a',
      city: 'Kreuzwertheim',
      position: { lat: 49.7710935, lng: 9.525941800000055 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3789',
      zip: '70825',
      name: 'Korntal',
      street: 'Johannes-Daur-Strasse 4',
      city: 'Korntal',
      position: { lat: 48.83087, lng: 9.121160000000032 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3710',
      zip: '71254',
      name: 'Ditzingen',
      street: 'Stuttgarter Str. 41',
      city: 'Ditzingen',
      position: { lat: 48.8237782, lng: 9.063999700000068 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3399',
      zip: '73207',
      name: 'Plochingen',
      street: 'Am Fischbrunnen 2',
      city: 'Plochingen',
      position: { lat: 48.71060440000001, lng: 9.41498439999998 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1154',
      zip: '73230',
      name: 'Kirchheim',
      street: 'Marktstr. 47',
      city: 'Kirchheim/Teck',
      position: { lat: 48.6468171, lng: 9.45154500000001 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1108',
      zip: '73312',
      name: 'Geislingen',
      street: 'Stuttgarter Str. 72',
      city: 'undefined',
      position: { lat: 48.6250332, lng: 9.82763209999996 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3612',
      zip: '80993',
      name: 'München OEZ',
      street: 'Hanauer Str. 68',
      city: 'München',
      position: { lat: 48.1834097, lng: 11.532569400000057 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1038',
      zip: '82515',
      name: 'Wolfratshausen Obermarkt 8',
      street: 'Obermarkt 8',
      city: 'Wolfratshausen',
      position: { lat: 47.913549, lng: 11.417616299999963 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1501',
      zip: '83174',
      name: 'Miesbach Wallenburger Straße',
      street: 'Wallenburger Str. 1',
      city: 'Miesbach',
      position: { lat: 47.7917278, lng: 11.831788800000027 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1443',
      zip: '83700',
      name: 'Rottach-Egern ',
      street: 'Ludwig-Thoma-Str. 4',
      city: 'Egern',
      position: { lat: 47.6909664, lng: 11.771649600000046 },
      region: 'DE',
      opening_hours: [],
      module: '0,5 m'
    },
    {
      id: '3771',
      zip: '85098',
      name: 'Großmehring',
      street: 'Flockstraße 37',
      city: 'Großmehring',
      position: { lat: 48.7665464, lng: 11.515684899999997 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3655',
      zip: '85101',
      name: 'Lenting',
      street: 'Nürnberger Str. 24',
      city: 'Lenting',
      position: { lat: 48.8145998, lng: 11.460015500000054 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1492',
      zip: '85221',
      name: 'Dachau Kopernikusstraße',
      street: 'Kopernikusstr. 7',
      city: 'Dachau',
      position: { lat: 48.2603698, lng: 11.476511999999957 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3678',
      zip: '95326',
      name: 'Kulmbach',
      street: 'Albert Ruckdeschel Str. 27a',
      city: 'Kulmbach',
      position: { lat: 50.1129344, lng: 11.45505270000001 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1331',
      zip: '95444',
      name: 'Bayreuth Rotmain-Center',
      street: 'Hohenzollernring 58',
      city: 'Bayreuth',
      position: { lat: 49.9454597, lng: 11.569800200000032 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3115',
      zip: '96047',
      name: 'Bamberg Maximiliansplatz',
      street: 'Maximiliansplatz 14',
      city: 'Bamberg',
      position: { lat: 49.8944504, lng: 10.89027950000002 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1477',
      zip: '96103',
      name: 'Hallstadt im market Einkaufszentrum',
      street: 'Michelinstr. 142',
      city: 'Hallstadt',
      position: { lat: 49.9233039, lng: 10.894720000000007 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1496',
      zip: '83059',
      name: 'Kolbermoor',
      street: 'Rosenheimer Str. 56',
      city: 'Kolbermoor',
      position: { lat: 47.8486462, lng: 12.076301000000058 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1484',
      zip: '83093',
      name: 'Bad Endorf',
      street: 'Bahnhofstr. 8',
      city: 'Bad Endorf',
      position: { lat: 47.9073722, lng: 12.298874599999976 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3665',
      zip: '86807',
      name: 'Buchloe Bahnhofstrasse',
      street: 'Neue Mitte 5',
      city: 'Buchloe',
      position: { lat: 48.03534399999999, lng: 10.718902000000071 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7194',
      zip: '89250',
      name: 'Senden Iller Center',
      street: 'Berliner Str. 9',
      city: 'Senden',
      position: { lat: 48.3317777, lng: 10.03894379999997 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1221',
      zip: '89269',
      name: 'Vöhringen',
      street: 'Industriestr. 31',
      city: 'Vöhringen',
      position: { lat: 48.2866051, lng: 10.078576399999974 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1335',
      zip: '91438',
      name: 'Bad Windsheim',
      street: 'Kegetstr. 3',
      city: 'Bad Windsheim',
      position: { lat: 49.50195799999999, lng: 10.417836699999953 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1426',
      zip: '91555',
      name: 'Feuchtwangen',
      street: 'Dinkelsbühler Str. 20',
      city: 'Feuchtwangen',
      position: { lat: 49.1647188, lng: 10.325962200000049 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1222',
      zip: '83278',
      name: 'MBS - Traunstein',
      street: 'Maximilianstr. 1',
      city: 'Traunstein',
      position: { lat: 47.8685532, lng: 12.64605210000002 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1056',
      zip: '87527',
      name: 'Sonthofen Bahnhofstraße',
      street: 'Bahnhofstr. 15',
      city: 'Sonthofen',
      position: { lat: 47.5120438, lng: 10.277055099999984 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1487',
      zip: '87527',
      name: 'Sonthofen Rudolf-Diesel-Straße',
      street: 'Rudolf-Diesel-Str. 7',
      city: 'Sonthofen',
      position: { lat: 47.5217752, lng: 10.270456100000047 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3624',
      zip: '87534',
      name: 'MBS - Oberstaufen',
      street: 'Bahnhofstr. 8',
      city: 'Oberstaufen',
      position: { lat: 47.5543488, lng: 10.024592999999982 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1355',
      zip: '88131',
      name: 'Lindau Lindaupark',
      street: 'Berliner Platz - Lindaupark',
      city: 'Lindau',
      position: { lat: 47.5539451, lng: 9.702931199999966 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3192',
      zip: '88161',
      name: 'Lindenberg',
      street: 'Kreuzhofstr. 1',
      city: 'Lindenberg',
      position: { lat: 47.6028045, lng: 9.889396499999975 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3747',
      zip: '83512',
      name: 'Wasserburg Am Aussichtsturm',
      street: 'Am Aussichtsturm 3-5',
      city: 'Wasserburg',
      position: { lat: 48.0562526, lng: 12.236105700000053 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7096',
      zip: '83527',
      name: 'Haag',
      street: 'Schäfflerstr. 4',
      city: 'Haag',
      position: { lat: 48.16439, lng: 12.168159999999943 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1097',
      zip: '88045',
      name: 'Friedrichshafen',
      street: 'Wilhelmstr. 31',
      city: 'Friedrichshafen',
      position: { lat: 47.65159000000001, lng: 9.48081000000002 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3111',
      zip: '88074',
      name: 'Meckenbeuren',
      street: 'Oskar-von-Miller-Platz 6',
      city: 'Meckenbeuren',
      position: { lat: 47.7002849, lng: 9.557648200000017 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3011',
      zip: '89134',
      name: 'Blaustein',
      street: 'Hummelstr. 7-11',
      city: 'Blaustein',
      position: { lat: 48.4142368, lng: 9.920939999999973 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1437',
      zip: '89143',
      name: 'Blaubeuren',
      street: 'Württemberger Str. 6',
      city: 'Blaubeuren',
      position: { lat: 48.4031435, lng: 9.790925099999981 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3520',
      zip: '89587',
      name: 'Ehingen Marktstraße',
      street: 'Marktstr. 1-3',
      city: 'Ehingen',
      position: { lat: 48.28243, lng: 9.72738000000004 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1360',
      zip: '73441',
      name: 'Bopfingen',
      street: 'Aalener Str. 60',
      city: 'Bopfingen',
      position: { lat: 48.86318, lng: 10.34235000000001 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1498',
      zip: '73450',
      name: 'Neresheim',
      street: 'Heidenheimer Str. 36',
      city: 'undefined',
      position: { lat: 48.7556506, lng: 10.319857400000046 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1155',
      zip: '73479',
      name: 'Ellwangen',
      street: 'Aalener Str. 1',
      city: 'Ellwangen',
      position: { lat: 48.96090969999999, lng: 10.12977449999994 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1488',
      zip: '83423',
      name: 'Bad Reichenhall',
      street: 'Ludwigsstr. 23',
      city: 'Bad Reichenhall',
      position: { lat: 47.7241765, lng: 12.878343299999983 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1403',
      zip: '83471',
      name: 'Berchtesgaden',
      street: 'Marktplatz 11-13',
      city: 'Berchtesgaden',
      position: { lat: 47.63260589999999, lng: 13.001498200000015 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7186',
      zip: '89160',
      name: 'Dornstadt',
      street: 'Alte Landstr. 5',
      city: 'Dornstadt',
      position: { lat: 48.4683773, lng: 9.945908299999928 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1024',
      zip: '89231',
      name: 'Neu-Ulm Offenhausen',
      street: 'Heinrich-Heine-Str. 2/1',
      city: 'Neu',
      position: { lat: 48.40058000000001, lng: 10.023410000000013 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1490',
      zip: '89257',
      name: 'Illertissen',
      street: 'Ulmer Str. 4',
      city: 'Illertissen',
      position: { lat: 48.223233, lng: 10.103246399999989 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3281',
      zip: '89264',
      name: 'Weissenhorn',
      street: 'Kaiser-Karl-Str. 22',
      city: 'Weissenhorn',
      position: { lat: 48.3069943, lng: 10.160329899999965 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1427',
      zip: '97437',
      name: 'Hassfurt Admiracenter',
      street: 'Zeilerstr. 14',
      city: 'undefined',
      position: { lat: 50.0302089, lng: 10.521737400000006 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1509',
      zip: '26723',
      name: 'Emden',
      street: 'Ubierstr. 2a',
      city: 'Emden',
      position: { lat: 53.36759, lng: 7.19186000000002 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7098',
      zip: '26802',
      name: 'Moormerland',
      street: 'Rudolf-Eucken-Str. 3',
      city: 'Moormerland',
      position: { lat: 53.30873159999999, lng: 7.486373299999968 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3414',
      zip: '1069',
      name: 'Dresden Centrum Galerie',
      street: 'Prager Str. 15',
      city: 'Dresden',
      position: { lat: 51.04586, lng: 13.733809899999983 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3568',
      zip: '4109',
      name: 'Leipzig Höfe am Brühl Einkaufszentrum',
      street: 'Brühl 1',
      city: 'Leipzig',
      position: { lat: 51.3436737, lng: 12.374330200000031 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7166',
      zip: '4109',
      name: 'Leipzig Petersstraße',
      street: 'Petersstr. 28',
      city: 'Leipzig',
      position: { lat: 51.33836179999999, lng: 12.374283200000036 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7137',
      zip: '9599',
      name: 'Freiberg',
      street: 'Häuersteig 5',
      city: 'Freiberg',
      position: { lat: 50.8961717, lng: 13.329569300000003 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3757',
      zip: '10243',
      name: 'Berlin East Side Mall',
      street: 'Tamara Danz Straße 11',
      city: 'Berlin',
      position: { lat: 52.5064596, lng: 13.446013600000015 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3802',
      zip: '12351',
      name: 'Berlin Gropius Passagen',
      street: 'Johannisthaler Chaussee 317',
      city: 'Berlin',
      position: { lat: 52.42950339999999, lng: 13.455992899999956 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3311',
      zip: '28237',
      name: 'Bremen Waterfront',
      street: 'AG-Weser-Str. 3',
      city: 'Bremen',
      position: { lat: 53.1114382, lng: 8.74825050000004 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3012',
      zip: '32339',
      name: 'Espelkamp',
      street: 'Breslauer Str. 54',
      city: 'Espelkamp',
      position: { lat: 52.3768888, lng: 8.63138559999993 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3662',
      zip: '32756',
      name: 'Detmold',
      street: 'Lange Str. 73',
      city: 'Detmold',
      position: { lat: 51.9362505, lng: 8.881698000000029 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3010',
      zip: '33602',
      name: 'Bielefeld',
      street: 'Bahnhofstr. 23',
      city: 'Bielefeld',
      position: { lat: 52.0250284, lng: 8.531174999999962 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3018',
      zip: '34212',
      name: 'Melsungen',
      street: 'Sparkassenplatz 2',
      city: 'Melsungen',
      position: { lat: 51.1332011, lng: 9.542298699999947 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3188',
      zip: '35260',
      name: 'Stadtallendorf',
      street: 'Herrenwaldstr. 4a',
      city: 'Stadtallendorf',
      position: { lat: 50.8264643, lng: 9.012393599999996 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3603',
      zip: '36251',
      name: 'Bad Hersfeld',
      street: 'Heinrich-von-Stephan-Str. 4 c',
      city: 'Bad Hersfeld',
      position: { lat: 50.8634482, lng: 9.707173799999964 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3750',
      zip: '40217',
      name: 'Düsseldorf',
      street: 'Friedrichstr. 133',
      city: 'Düsseldorf',
      position: { lat: 51.208393, lng: 6.774280299999987 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3701',
      zip: '40721',
      name: 'Hilden',
      street: 'Mittelstr. 17',
      city: 'Hilden',
      position: { lat: 51.1689415, lng: 6.938731899999993 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3184',
      zip: '40764',
      name: 'Langenfeld',
      street: 'Solingerstr. 20-22',
      city: 'Langenfeld',
      position: { lat: 51.110689, lng: 6.950099499999965 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3712',
      zip: '41061',
      name: 'Mönchengladbach',
      street: 'Hindenburgstrasse 73',
      city: 'Mönchengladbach',
      position: { lat: 51.1943661, lng: 6.437288200000012 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1441',
      zip: '53343',
      name: 'Wachtberg',
      street: 'Am Wachtberg Ring 7',
      city: 'Wachtberg',
      position: { lat: 50.6217372, lng: 7.122690599999942 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3214',
      zip: '53424',
      name: 'Remagen',
      street: 'Goethestr. 7',
      city: 'Remagen',
      position: { lat: 50.576739, lng: 7.243136600000071 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3709',
      zip: '53757',
      name: 'St. Augustin',
      street: 'Rathausallee 16',
      city: 'St. Augustin',
      position: { lat: 50.7784023, lng: 7.186461000000008 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3608',
      zip: '53840',
      name: 'Troisdorf Galerie',
      street: 'Wilhelm Hamacher Platz 22',
      city: 'Troisdorf',
      position: { lat: 50.814183, lng: 7.156096199999979 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3061',
      zip: '59755',
      name: 'Arnsberg-Neheim',
      street: 'Hauptstr. 36',
      city: 'Arnsberg',
      position: { lat: 51.4515002, lng: 7.963204399999995 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3805',
      zip: '59872',
      name: 'Meschede',
      street: 'Winziger Platz 8-10',
      city: 'Meschede',
      position: { lat: 51.34759, lng: 8.28513989999999 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3141',
      zip: '74072',
      name: 'Heilbronn Kiliansplatz',
      street: 'Kilianstr. 11',
      city: 'Heilbronn',
      position: { lat: 49.14098, lng: 9.220900000000029 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1220',
      zip: '74523',
      name: 'Schwäbisch Hall',
      street: 'Neue Str. 22 - 24',
      city: 'Schwäbisch Hall',
      position: { lat: 49.1131574, lng: 9.734499700000015 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1079',
      zip: '83022',
      name: 'Rosenheim',
      street: 'Kufsteiner Str. 7',
      city: 'Rosenheim',
      position: { lat: 47.8531371, lng: 12.126604799999996 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3691',
      zip: '83301',
      name: 'Traunreut',
      street: 'Werner-von-Siemens-Str. 2',
      city: 'Traunreut',
      position: { lat: 47.9664026, lng: 12.586022899999989 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1226',
      zip: '83395',
      name: 'Freilassing',
      street: 'Hauptstr. 11-13',
      city: 'Freilassing',
      position: { lat: 47.8396134, lng: 12.981960299999969 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3785',
      zip: '84028',
      name: 'Landshut - Altstadt',
      street: 'Altstadt 82-84',
      city: 'Landshut',
      position: { lat: 48.53655999999999, lng: 12.151010000000042 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3201',
      zip: '84453',
      name: 'Mühldorf',
      street: 'Stadtplatz 44',
      city: 'Mühldorf',
      position: { lat: 48.241691, lng: 12.523407499999962 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1411',
      zip: '87435',
      name: 'Kempten Forum Allgäu',
      street: 'Einkaufszentrum August-Fischer-Platz',
      city: 'Kempten',
      position: { lat: 47.7206511, lng: 10.31508980000001 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3569',
      zip: '87435',
      name: 'Kempten Fischerstrasse',
      street: 'Fischerstr. 2',
      city: 'Kempten',
      position: { lat: 47.7243552, lng: 10.316221000000041 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1070',
      zip: '87600',
      name: 'Kaufbeuren Schmiedgasse',
      street: 'Schmiedgasse 11',
      city: 'Kaufbeuren',
      position: { lat: 47.88107, lng: 10.618449999999939 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1136',
      zip: '87629',
      name: 'Füssen',
      street: 'Kemptener Str. 1',
      city: 'Füssen',
      position: { lat: 47.5676492, lng: 10.697077199999967 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3767',
      zip: '87666',
      name: 'Pforzen',
      street: 'Am Moosberg 4',
      city: 'Pforzen',
      position: { lat: 47.9346126, lng: 10.608150799999976 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1352',
      zip: '90443',
      name: 'Nürnberg Bahnhof',
      street: 'Bahnhofsplatz 9',
      city: 'Nürnberg',
      position: { lat: 49.4463586, lng: 11.081627199999957 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3619',
      zip: '90547',
      name: 'Stein',
      street: 'Forum 1',
      city: 'Stein',
      position: { lat: 49.4115312, lng: 11.012410300000056 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1163',
      zip: '90762',
      name: 'Fürth',
      street: 'Schwabacher Str. 28-30',
      city: 'Fürth',
      position: { lat: 49.4744101, lng: 10.988567200000034 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3109',
      zip: '91052',
      name: 'Erlangen Nürnberger Straße',
      street: 'Nürnberger Str. 10',
      city: 'Erlangen',
      position: { lat: 49.5940717, lng: 11.00579719999996 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3015',
      zip: '92342',
      name: 'Freystadt',
      street: 'Berchinger Str. 38 b',
      city: 'Freystadt',
      position: { lat: 49.1934642, lng: 11.333683899999983 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1089',
      zip: '93047',
      name: 'Regensburg Weiße-Lilien-Straße',
      street: 'Weiße-Lilien-Str. 13',
      city: 'Regensburg',
      position: { lat: 49.0170401, lng: 12.098577400000067 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1347',
      zip: '93059',
      name: 'Regensburg Donau Einkaufszentrum',
      street: 'Weichser Weg 5',
      city: 'Regensburg',
      position: { lat: 49.026573, lng: 12.116399099999967 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1472',
      zip: '93142',
      name: 'Maxhütte-Haidhof',
      street: 'Nordgaustr. 4',
      city: 'Maxhütte',
      position: { lat: 49.1923432, lng: 12.10007029999997 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1389',
      zip: '93326',
      name: 'Abensberg',
      street: 'Straubinger Str.',
      city: 'Abensberg',
      position: { lat: 48.8110867, lng: 11.858951599999955 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1455',
      zip: '94032',
      name: 'Passau Bahnhofstraße',
      street: 'Bahnhofstr. 14',
      city: 'Passau',
      position: { lat: 48.5744277, lng: 13.45416130000001 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1356',
      zip: '94060',
      name: 'Pocking',
      street: 'Indlinger Str. 11',
      city: 'Pocking',
      position: { lat: 48.4018525, lng: 13.313961199999994 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7176',
      zip: '97070',
      name: 'Würzburg',
      street: 'Dominikanerplatz 4',
      city: 'Würzburg',
      position: { lat: 49.796386, lng: 9.931453000000033 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7154',
      zip: '99084',
      name: 'Erfurt',
      street: 'Schlösserstr. 19',
      city: 'Erfurt',
      position: { lat: 50.9766515, lng: 11.032303599999977 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7161',
      zip: '99423',
      name: 'Weimar',
      street: 'Schillerstr. 8',
      city: 'Weimar',
      position: { lat: 50.9788752, lng: 11.328651600000057 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7195',
      zip: '4329',
      name: 'Leipzig P.C. Paunsdorf Center',
      street: 'Paunsdorf Allee 1',
      city: 'Leipzig',
      position: { lat: 51.3488234, lng: 12.476580399999989 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7193',
      zip: '6237',
      name: 'Günthersdorf Einkaufszentrum Nova Eventis',
      street: 'Nova Eventis',
      city: 'Leuna / OT Günthersdorf',
      position: { lat: 51.3443888, lng: 12.181059699999992 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7180',
      zip: '7545',
      name: 'Gera',
      street: 'Schloßstraße 12',
      city: 'Gera',
      position: { lat: 50.8781765, lng: 12.081621499999983 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7160',
      zip: '7743',
      name: 'Jena',
      street: 'Unterm Markt 4',
      city: 'Jena',
      position: { lat: 50.9275361, lng: 11.588887900000032 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3567',
      zip: '21335',
      name: 'Lüneburg',
      street: 'Grapengießerstr. 7',
      city: 'Lüneburg',
      position: { lat: 53.2476022, lng: 10.407837500000028 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3215',
      zip: '29221',
      name: 'Celle',
      street: 'Zöllnerstr. 44-46',
      city: 'Celle',
      position: { lat: 52.6240476, lng: 10.08214810000004 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3297',
      zip: '29525',
      name: 'Uelzen',
      street: 'Gudesstr. 13-23',
      city: 'Uelzen',
      position: { lat: 52.9650617, lng: 10.56231379999997 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3711',
      zip: '36037',
      name: 'Fulda Bahnhofstrasse',
      street: 'Bahnhofstr. 17',
      city: 'Fulda',
      position: { lat: 50.5535062, lng: 9.681331999999998 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1417',
      zip: '36043',
      name: 'Fulda Kaiserwiesen',
      street: 'Keltenstr. 20',
      city: 'Fulda',
      position: { lat: 50.5313892, lng: 9.691332200000033 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '2126',
      zip: '36110',
      name: 'Schlitz',
      street: 'Bahnhofstr. 52',
      city: 'Schlitz',
      position: { lat: 50.681615, lng: 9.56488509999997 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3737',
      zip: '36304',
      name: 'Alsfeld',
      street: 'Schellengasse 41',
      city: 'Alsfeld',
      position: { lat: 50.7516071, lng: 9.278818499999943 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1383',
      zip: '45711',
      name: 'Datteln',
      street: 'Hohe Str. 32',
      city: 'Datteln',
      position: { lat: 51.6531829, lng: 7.340832500000033 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1379',
      zip: '45879',
      name: 'Gelsenkirchen',
      street: 'Bahnhofstr. 55/56',
      city: 'Gelsenkirchen',
      position: { lat: 51.5075209, lng: 7.099521900000013 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3690',
      zip: '46262',
      name: 'Dorsten',
      street: 'Westwall 61',
      city: 'Dorsten',
      position: { lat: 51.66368689999999, lng: 6.964896100000033 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3703',
      zip: '50354',
      name: 'Hürth',
      street: 'Hürth-Park L020',
      city: 'Hürth',
      position: { lat: 50.8799337, lng: 6.872945200000004 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1469',
      zip: '51103',
      name: 'Köln Arcaden',
      street: 'Kalker Hauptstr. 55',
      city: 'Köln',
      position: { lat: 50.9381279, lng: 6.9980233 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3566',
      zip: '52525',
      name: 'Heinsberg',
      street: 'Hochstr. 123',
      city: 'Heinsberg',
      position: { lat: 51.06306660000001, lng: 6.096032499999978 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3204',
      zip: '54290',
      name: 'Trier',
      street: 'Grabenstr. 17',
      city: 'Trier',
      position: { lat: 49.7559167, lng: 6.6409384999999475 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3292',
      zip: '54516',
      name: 'Wittlich Schlossgalerie',
      street: 'Schlossstr. 5',
      city: 'Wittlich',
      position: { lat: 49.9857981, lng: 6.890707700000007 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1450',
      zip: '57072',
      name: 'Siegen City Galerie',
      street: 'Am Bahnhof 40',
      city: 'Siegen',
      position: { lat: 50.8741699, lng: 8.015129699999989 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1474',
      zip: '57462',
      name: 'Olpe',
      street: 'Kölner Str. 7',
      city: 'Olpe',
      position: { lat: 51.02881259999999, lng: 7.843786799999975 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3616',
      zip: '58511',
      name: 'Lüdenscheid',
      street: 'Wilhelmstr. 33',
      city: 'Lüdenscheid',
      position: { lat: 51.2157887, lng: 7.631634200000008 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1146',
      zip: '63739',
      name: 'Aschaffenburg City Galerie',
      street: 'Fabrikstr. 1B',
      city: 'Aschaffenburg',
      position: { lat: 49.9779213, lng: 9.151701199999934 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3584',
      zip: '63762',
      name: 'Großostheim',
      street: 'Raiffeisenstr. 8',
      city: 'Großostheim',
      position: { lat: 49.9261792, lng: 9.08250709999993 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3810',
      zip: '64319',
      name: 'Pfungstadt',
      street: 'Eberstädter Straße 200',
      city: 'Pfungstadt',
      position: { lat: 49.8137426, lng: 8.61892940000007 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3696',
      zip: '70806',
      name: 'Kornwestheim',
      street: 'Holzgrundstr. 3',
      city: 'Kornwestheim',
      position: { lat: 48.8651005, lng: 9.18134980000002 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3013',
      zip: '71332',
      name: 'Waiblingen',
      street: 'Alter Postplatz 9',
      city: 'Waiblingen',
      position: { lat: 48.8303515, lng: 9.315071200000034 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1369',
      zip: '71634',
      name: 'Ludwigsburg Breuningerland',
      street: 'Heinkelstr. 1-11',
      city: 'Ludwigsburg',
      position: { lat: 48.9213616, lng: 9.151803800000039 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1415',
      zip: '71638',
      name: 'Ludwigsburg Wilhelm Galerie',
      street: 'Wilhelmstr. 26',
      city: 'Ludwigsburg',
      position: { lat: 48.896327, lng: 9.188843799999972 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1373',
      zip: '76133',
      name: 'Karlsruhe Kaiserstrasse',
      street: 'Kaiserstr. 169',
      city: 'Karlsruhe',
      position: { lat: 49.00955949999999, lng: 8.400259199999937 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3738',
      zip: '76133',
      name: 'Karlsruhe Ettlinger Tor',
      street: 'Ettlinger-Tor-Platz 1',
      city: 'Karlsruhe',
      position: { lat: 49.00645309999999, lng: 8.402945700000032 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1505',
      zip: '76275',
      name: 'Ettlingen',
      street: 'Neuer Markt 4',
      city: 'Ettlingen',
      position: { lat: 48.9418222, lng: 8.40659149999999 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3657',
      zip: '76437',
      name: 'Rastatt',
      street: 'Bahnhofstr. 2-4',
      city: 'Rastatt',
      position: { lat: 48.85753, lng: 8.20726000000002 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '2149',
      zip: '76530',
      name: 'Baden-Baden Wagener Galerie',
      street: 'Lange Str. 44',
      city: 'Baden',
      position: { lat: 48.7642013, lng: 8.238183700000036 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1473',
      zip: '76532',
      name: 'Baden-Baden Shopping Cité',
      street: 'Gewerbepark Cité 7',
      city: 'Baden',
      position: { lat: 48.7821895, lng: 8.191104999999993 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3540',
      zip: '76571',
      name: 'Gaggenau Badisches Einkaufszentrum',
      street: 'Hauptstr. 2-4',
      city: 'Gaggenau',
      position: { lat: 48.8054525, lng: 8.317936000000032 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '2120',
      zip: '76744',
      name: 'Wörth am Rhein Maximilian Center',
      street: 'Maximilianstr. 1',
      city: 'Wörth am Rhein',
      position: { lat: 49.0407594, lng: 8.29503879999993 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1170',
      zip: '89073',
      name: 'Ulm Hirschstraße',
      street: 'Hirschstr. 17 - 19',
      city: 'Ulm',
      position: { lat: 48.3981941, lng: 9.988069399999972 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1278',
      zip: '89073',
      name: 'Ulm',
      street: 'Frauenstr. 51',
      city: 'undefined',
      position: { lat: 48.4013559, lng: 9.99654499999997 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1334',
      zip: '89077',
      name: 'Ulm Blautalcenter',
      street: 'Blaubeurerstr. 95',
      city: 'Ulm',
      position: { lat: 48.3995791, lng: 9.961043099999983 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1363',
      zip: '91126',
      name: 'Schwabach Ludwigstraße',
      street: 'Ludwigstr. 7/9',
      city: 'Schwabach',
      position: { lat: 49.32927, lng: 11.023969999999963 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1332',
      zip: '91522',
      name: 'Ansbach Brücken-Center',
      street: 'Residenzstr. 2-6',
      city: 'Ansbach',
      position: { lat: 49.3059008, lng: 10.569567300000017 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1230',
      zip: '97616',
      name: 'Bad Neustadt/Saale',
      street: 'Marktplatz 31',
      city: 'Bad Neustadt',
      position: { lat: 50.3229433, lng: 10.215213100000028 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1397',
      zip: '97631',
      name: 'Bad Königshofen',
      street: 'Hindenburgstr. 5-7',
      city: 'Bad Königshofen',
      position: { lat: 50.2986559, lng: 10.466983400000004 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7141',
      zip: '98617',
      name: 'Meiningen',
      street: 'Georgstr. 19',
      city: 'Meiningen',
      position: { lat: 50.5695633, lng: 10.415954000000056 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3762',
      zip: '24937',
      name: 'Flensburg',
      street: 'Holm 57-61',
      city: 'Flensburg',
      position: { lat: 54.7834602, lng: 9.438206499999978 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3564',
      zip: '26122',
      name: 'Oldenburg Achternstraße',
      street: 'Achternstr. 9-10',
      city: 'Oldenburg',
      position: { lat: 53.14138999999999, lng: 8.214200000000005 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3020',
      zip: '26129',
      name: 'Oldenburg Familia Einkaufsland Wechloy',
      street: 'Posthalterweg 10',
      city: 'Oldenburg',
      position: { lat: 53.15848680000001, lng: 8.175499299999956 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1407',
      zip: '34117',
      name: 'Kassel Königsplatz',
      street: 'Königsplatz 32-34',
      city: 'Kassel',
      position: { lat: 51.3151093, lng: 9.49755730000004 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3684',
      zip: '37671',
      name: 'Höxter',
      street: 'Am Markt 1',
      city: 'Höxter',
      position: { lat: 51.7747865, lng: 9.381844099999967 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1361',
      zip: '38440',
      name: 'Wolfsburg',
      street: 'Porschestr. 68',
      city: 'Wolfsburg',
      position: { lat: 52.4219574, lng: 10.786328300000037 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3759',
      zip: '44137',
      name: 'Dortmund',
      street: 'Westenhellweg 44-46',
      city: 'Dortmund',
      position: { lat: 51.5141911, lng: 7.4635461000000305 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1345',
      zip: '44532',
      name: 'Luenen',
      street: 'Lange Str. 15 - 19',
      city: 'Lünen',
      position: { lat: 51.61406340000001, lng: 7.52340270000002 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1459',
      zip: '51465',
      name: 'Bergisch Gladbach Rhein Berg Galerie',
      street: 'Hauptstr. 131',
      city: 'Bergisch Gladbach',
      position: { lat: 50.9903796, lng: 7.124353599999949 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3659',
      zip: '51643',
      name: 'Gummersbach ',
      street: 'Steinmüllerallee 5',
      city: 'undefined',
      position: { lat: 51.0249754, lng: 7.565415300000041 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3692',
      zip: '59423',
      name: 'Unna',
      street: 'Bahnhofstr. 32',
      city: 'Unna',
      position: { lat: 51.53705, lng: 7.690820000000031 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1393',
      zip: '59494',
      name: 'Soest',
      street: 'Brüderstr. 36',
      city: 'Soest',
      position: { lat: 51.5749464, lng: 8.10550139999998 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3807',
      zip: '60385',
      name: 'Frankfurt',
      street: 'Berger Straße 125-129',
      city: 'Frankfurt',
      position: { lat: 50.12395, lng: 8.702949999999987 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3686',
      zip: '66538',
      name: 'Neunkirchen / Saar',
      street: 'Stummplatz 1',
      city: 'Neunkirchen / Saar',
      position: { lat: 49.3483727, lng: 7.174137100000053 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3687',
      zip: '66740',
      name: 'Saarlouis',
      street: 'Im Hader 1-3',
      city: 'Saarlouis',
      position: { lat: 49.2941148, lng: 6.759915800000044 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1330',
      zip: '66953',
      name: 'Pirmasens',
      street: 'Hauptstr. 38',
      city: 'Pirmasens',
      position: { lat: 49.19952809999999, lng: 7.604210200000011 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1329',
      zip: '67433',
      name: 'Neustadt/Weinstraße',
      street: 'Hauptstr. 41-43',
      city: 'Neustadt / Weinstraße',
      position: { lat: 49.351566, lng: 8.136394800000062 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1196',
      zip: '67655',
      name: 'Kaiserslautern',
      street: 'Eisenbahnstr. 21 - 25',
      city: 'Kaiserslautern',
      position: { lat: 49.4416941, lng: 7.771367199999986 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1279',
      zip: '72072',
      name: 'Tübingen',
      street: 'Friedrichstr. 7',
      city: 'Tübingen',
      position: { lat: 48.5178397, lng: 9.059546599999976 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3009',
      zip: '72116',
      name: 'Mössingen',
      street: 'Jakobstr. 14',
      city: 'Mössingen',
      position: { lat: 48.4073741, lng: 9.054543299999978 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3575',
      zip: '72458',
      name: 'Albstadt-Ebingen',
      street: 'Kirchengraben 38-42',
      city: 'Albstadt',
      position: { lat: 48.21216, lng: 9.026774799999998 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3548',
      zip: '72574',
      name: 'Bad Urach',
      street: 'Seilerweg 4',
      city: 'Bad Urach',
      position: { lat: 48.4898374, lng: 9.399558399999933 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1033',
      zip: '72764',
      name: 'Reutlingen',
      street: 'Wilhelmstr. 40',
      city: 'Reutlingen',
      position: { lat: 48.4926797, lng: 9.210245999999984 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1133',
      zip: '73728',
      name: 'Esslingen',
      street: 'Pliensaustr. 31',
      city: 'Esslingen',
      position: { lat: 48.73888179999999, lng: 9.304926099999989 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '2138',
      zip: '73760',
      name: 'Ostfildern',
      street: 'Bonhoeffer Str. 2',
      city: 'Ostfildern',
      position: { lat: 48.721858, lng: 9.270612000000028 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1282',
      zip: '76829',
      name: 'Landau/Pfalz',
      street: 'Kronstr. 6',
      city: 'Landau',
      position: { lat: 49.1983472, lng: 8.11290729999996 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3550',
      zip: '89312',
      name: 'Günzburg B.-Landmann-Platz',
      street: 'Bürgermeister-Landmann-Platz 7',
      city: 'Günzburg',
      position: { lat: 48.4542826, lng: 10.278375099999948 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3574',
      zip: '89415',
      name: 'Lauingen Herzog-Georg-Straße',
      street: 'Herzog - Georg - Str. 58',
      city: 'Lauingen',
      position: { lat: 48.5695321, lng: 10.429136800000038 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1386',
      zip: '89518',
      name: 'Heidenheim',
      street: 'Brenzstr. / Karlstr.',
      city: 'Heidenheim',
      position: { lat: 48.67821989999999, lng: 10.152029500000026 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7188',
      zip: '89542',
      name: 'Herbrechtingen',
      street: 'Zeppelinweg 3',
      city: 'Herbrechtingen',
      position: { lat: 48.6286816, lng: 10.189134299999978 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3749',
      zip: '22111',
      name: 'Hamburg Billstedt',
      street: 'Möllner Landstr. 3',
      city: 'Hamburg',
      position: { lat: 53.5407747, lng: 10.105364099999974 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3098',
      zip: '30823',
      name: 'Garbsen',
      street: 'Havelser Str. 1',
      city: 'Garbsen',
      position: { lat: 52.42650210000001, lng: 9.596644999999967 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3021',
      zip: '31785',
      name: 'Hameln Stadt Galerie',
      street: 'Pferdemarkt 1',
      city: 'Hameln',
      position: { lat: 52.1041451, lng: 9.356592999999975 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7181',
      zip: '37154',
      name: 'Northeim',
      street: 'Markt 16',
      city: 'Northeim',
      position: { lat: 51.7063284, lng: 9.999167599999964 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3713',
      zip: '42103',
      name: 'Wuppertal Elberfeld',
      street: 'Wall 18-20',
      city: 'Wuppertal',
      position: { lat: 51.2574738, lng: 7.14597219999996 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1382',
      zip: '42275',
      name: 'Wuppertal Barmen',
      street: 'Alter Markt 1',
      city: 'Wuppertal',
      position: { lat: 51.27047, lng: 7.196900000000028 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3022',
      zip: '58095',
      name: 'Hagen',
      street: 'Hohenzollernstr. 3-11',
      city: 'Hagen',
      position: { lat: 51.3584922, lng: 7.472270799999933 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3638',
      zip: '60594',
      name: 'Frankfurt - Schweizer Straße',
      street: 'Schweizer Str. 33-35',
      city: 'Frankfurt',
      position: { lat: 50.1030905, lng: 8.680299099999957 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3519',
      zip: '63065',
      name: 'Offenbach KOMM Einkaufscenter',
      street: 'Aliceplatz 11',
      city: 'Offenbach',
      position: { lat: 50.1036406, lng: 8.761247499999968 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3026',
      zip: '63128',
      name: 'Dietzenbach Rathaus Center',
      street: 'Offenbacher Str. 9',
      city: 'Dietzenbach',
      position: { lat: 50.0180376, lng: 8.783075899999972 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1396',
      zip: '63263',
      name: 'Neu-Isenburg Isenburg Zentrum',
      street: 'Hermesstr. 4',
      city: 'Neu',
      position: { lat: 50.0487954, lng: 8.696007399999985 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1362',
      zip: '67059',
      name: 'Ludwigshafen',
      street: 'Rathausplatz 21',
      city: 'Ludwigshafen',
      position: { lat: 49.48433, lng: 8.443390000000022 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7171',
      zip: '67227',
      name: 'Frankenthal',
      street: 'Wormserstraße 8 - 10',
      city: 'Frankenthal',
      position: { lat: 49.5361905, lng: 8.35449170000004 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3563',
      zip: '67346',
      name: 'Speyer',
      street: 'Maximilianstr. 34',
      city: 'Speyer',
      position: { lat: 49.317307, lng: 8.43518599999993 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1413',
      zip: '67547',
      name: 'Worms Kaiser Passage',
      street: 'Am Römischen Kaiser 9',
      city: 'Worms',
      position: { lat: 49.6315192, lng: 8.365019699999948 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3656',
      zip: '68519',
      name: 'Viernheim Rhein-Neckar-Zentrum',
      street: 'Robert-Schumann-Str. 8',
      city: 'Viernheim',
      position: { lat: 49.52790419999999, lng: 8.566972999999962 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1495',
      zip: '68623',
      name: 'Lampertheim',
      street: 'Industriestr. 14',
      city: 'Lampertheim',
      position: { lat: 49.5972119, lng: 8.48183270000004 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1085',
      zip: '68723',
      name: 'Schwetzingen',
      street: 'Mannheimer Str. 42 - 44',
      city: 'Schwetzingen',
      position: { lat: 49.3871756, lng: 8.572679600000015 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3607',
      zip: '69115',
      name: 'Heidelberg Bismarckplatz',
      street: 'Bergheimer Str.4',
      city: 'Heidelberg',
      position: { lat: 49.4096235, lng: 8.692574700000023 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1167',
      zip: '69469',
      name: 'Weinheim',
      street: 'Hauptstr. 100',
      city: 'Weinheim',
      position: { lat: 49.5481955, lng: 8.672311300000047 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1480',
      zip: '70173',
      name: 'Stuttgart Königstraße',
      street: 'Königstr. 5',
      city: 'Stuttgart',
      position: { lat: 48.7800082, lng: 9.180428600000027 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3059',
      zip: '70173',
      name: 'Stuttgart Bahnhof',
      street: 'Arnulf-Klett-Platz 2',
      city: 'Stuttgart',
      position: { lat: 48.78472439999999, lng: 9.1833216 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1440',
      zip: '70372',
      name: 'Stuttgart Carré Bad Cannstatt',
      street: 'Wildunger Str. 2',
      city: 'Bad Cannstatt',
      position: { lat: 48.802125, lng: 9.221186399999965 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1321',
      zip: '73037',
      name: 'Göppingen B10 Center ',
      street: 'Esslinger Str. 1 ',
      city: 'Göppingen',
      position: { lat: 48.6974363, lng: 9.678204199999982 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3694',
      zip: '60439',
      name: 'Frankfurt Nord-Westzentrum',
      street: 'Limescorso 8',
      city: 'Frankfurt',
      position: { lat: 50.1576126, lng: 8.633451599999944 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3527',
      zip: '61118',
      name: 'Bad Vilbel',
      street: 'Niddaplatz 3',
      city: 'Bad Vilbel',
      position: { lat: 50.1804972, lng: 8.739744100000053 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1104',
      zip: '61348',
      name: 'Bad Homburg',
      street: 'Louisenstr. 80 - 82',
      city: 'Bad Homburg',
      position: { lat: 50.2255276, lng: 8.619935499999997 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3529',
      zip: '61381',
      name: 'Friedrichsdorf Taunus-Carré',
      street: 'Wilhelmstr. 23',
      city: 'Friedrichsdorf',
      position: { lat: 50.2546662, lng: 8.642813199999978 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3212',
      zip: '61462',
      name: 'Königstein',
      street: 'Frankfurter Str. 9',
      city: 'Königstein',
      position: { lat: 50.1819561, lng: 8.470351100000016 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3617',
      zip: '78244',
      name: 'Gottmadingen',
      street: 'Hauptstr. 9/1',
      city: 'Gottmadingen',
      position: { lat: 47.7360859, lng: 8.772657200000026 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '7172',
      zip: '78462',
      name: 'Konstanz Marktstätte',
      street: 'Marktstätte 13',
      city: 'Konstanz 2',
      position: { lat: 47.6600605, lng: 9.175517899999932 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3706',
      zip: '55411',
      name: 'Bingen / Rhein Basilikastr.',
      street: 'Basilikastr. 3',
      city: 'Bingen / Rhein',
      position: { lat: 49.9678135, lng: 7.892969200000039 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1404',
      zip: '77652',
      name: 'Offenburg',
      street: 'Hauptstr. 72',
      city: 'Offenburg',
      position: { lat: 48.4709617, lng: 7.942498800000067 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1138',
      zip: '78050',
      name: 'Villingen',
      street: 'Rietstr. 5',
      city: 'VS',
      position: { lat: 48.0595663, lng: 8.459377000000018 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1483',
      zip: '78054',
      name: 'Schwenningen City Rondell Einkaufszentrum',
      street: 'Kronenstr. 21',
      city: 'VS',
      position: { lat: 48.06323, lng: 8.534179999999992 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1077',
      zip: '79098',
      name: 'Freiburg Auf der Zinnen',
      street: 'Auf der Zinnen 1',
      city: 'Freiburg',
      position: { lat: 47.9974735, lng: 7.854163900000003 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1410',
      zip: '79098',
      name: 'Freiburg Kaiser-Joseph-Straße',
      street: 'Kaiser-Joseph-Str. 208-212',
      city: 'Freiburg',
      position: { lat: 47.99558, lng: 7.84996000000001 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3578',
      zip: '79189',
      name: 'Bad Krozingen Breisgaustraße',
      street: 'Breisgauer Str. 9',
      city: 'Bad Krozingen',
      position: { lat: 47.92144, lng: 7.7041000000000395 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3524',
      zip: '79312',
      name: 'Emmendingen',
      street: 'Freiburger Str. 2-8',
      city: 'Emmendingen',
      position: { lat: 48.1189972, lng: 7.849739699999986 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1384',
      zip: '88212',
      name: 'Ravensburg Adlerstraße',
      street: 'Adlerstr. 30',
      city: 'Ravensburg',
      position: { lat: 47.782302, lng: 9.610734699999966 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1503',
      zip: '88630',
      name: 'Pfullendorf',
      street: 'St. Katharinenstr. 1/1',
      city: 'Pfullendorf',
      position: { lat: 47.9247862, lng: 9.250255599999946 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3304',
      zip: '88662',
      name: 'Überlingen Christophstraße',
      street: 'Christophstr. 25',
      city: 'Überlingen',
      position: { lat: 47.7678247, lng: 9.1576867 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1416',
      zip: '88677',
      name: 'Markdorf',
      street: 'Hauptstr. 32',
      city: 'Markdorf',
      position: { lat: 47.7218564, lng: 9.38617709999994 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1042',
      zip: '92224',
      name: 'Amberg',
      street: 'Rathausstr. 2',
      city: 'Amberg',
      position: { lat: 49.4449678, lng: 11.85857390000001 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1124',
      zip: '92318',
      name: 'Neumarkt',
      street: 'Obere Marktstr. 51',
      city: 'Neumarkt/Oberpfalz',
      position: { lat: 49.2776726, lng: 11.460672199999976 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1258',
      zip: '92637',
      name: 'Weiden',
      street: 'Max-Reger-Str.16',
      city: 'Weiden',
      position: { lat: 49.6743351, lng: 12.157412000000022 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1460',
      zip: '93413',
      name: 'Cham',
      street: 'Rodinger Str. 3',
      city: 'Cham',
      position: { lat: 49.2020839, lng: 12.658237699999972 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1486',
      zip: '35390',
      name: 'Gießen Galerie Neustädter Tor',
      street: 'Neustadt 28',
      city: 'Gießen',
      position: { lat: 50.5872154, lng: 8.671029299999987 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '7163',
      zip: '35576',
      name: 'Wetzlar',
      street: 'Buderusplatz 2',
      city: 'Wetzlar',
      position: { lat: 50.56090289999999, lng: 8.50244650000002 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3528',
      zip: '55116',
      name: 'Mainz',
      street: 'Schusterstr. 7',
      city: 'Mainz',
      position: { lat: 49.99978369999999, lng: 8.272628899999972 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3517',
      zip: '55218',
      name: 'Ingelheim',
      street: 'Binger Str. 82',
      city: 'Ingelheim',
      position: { lat: 49.9749102, lng: 8.055164800000057 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1333',
      zip: '55543',
      name: 'Bad Kreuznach',
      street: 'Kreuzstr. 24',
      city: 'Bad Kreuznach',
      position: { lat: 49.8439576, lng: 7.860445499999969 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1317',
      zip: '71083',
      name: 'Herrenberg',
      street: 'Bronntor 1',
      city: 'Herrenberg',
      position: { lat: 48.5961425, lng: 8.86803980000002 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3715',
      zip: '72160',
      name: 'Horb am Neckar Arcaden',
      street: 'Bahnhofsplatz 1',
      city: 'Horb am Neckar',
      position: { lat: 48.4422738, lng: 8.69077100000004 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1172',
      zip: '72202',
      name: 'Nagold',
      street: 'Marktstr. 28',
      city: 'Nagold',
      position: { lat: 48.5515804, lng: 8.723070399999983 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3702',
      zip: '72250',
      name: 'Freudenstadt',
      street: 'Ludwig-Jahn-Str. 34',
      city: 'Freudenstadt',
      position: { lat: 48.4694477, lng: 8.414868500000011 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3726',
      zip: '79379',
      name: 'Müllheim',
      street: 'Werderstr. 40a',
      city: 'Müllheim',
      position: { lat: 47.8080703, lng: 7.627837600000021 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1479',
      zip: '79539',
      name: 'Lörrach',
      street: 'Tumringer Str. 175-177',
      city: 'Lörrach',
      position: { lat: 47.61228999999999, lng: 7.6607400000000325 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1478',
      zip: '79576',
      name: 'Weil / Rhein Hauptstraße 335',
      street: 'Hauptstr. 335',
      city: 'Weil am Rhein',
      position: { lat: 47.5923202, lng: 7.6092398000000685 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3602',
      zip: '79588',
      name: 'Efringen-Kirchen',
      street: 'Im Schlöttle 5',
      city: 'Efringen',
      position: { lat: 47.656933, lng: 7.574918000000025 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3525',
      zip: '65719',
      name: 'Hofheim am Taunus Chinon Center',
      street: 'Chinonplatz 6-12',
      city: 'Hofheim am Taunus',
      position: { lat: 50.0860284, lng: 8.448342700000012 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3116',
      zip: '65779',
      name: 'Kelkheim',
      street: 'Marktplatz 4',
      city: 'Kelkheim',
      position: { lat: 50.134721, lng: 8.453305 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3000',
      zip: '65795',
      name: 'Hattersheim',
      street: 'Untertorstr. 11',
      city: 'Hattersheim',
      position: { lat: 50.06665, lng: 8.491089999999986 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '2131',
      zip: '70329',
      name: 'Stuttgart Hedelfingen',
      street: 'Otto-Hirsch-Brücken 17',
      city: 'Stuttgart',
      position: { lat: 48.760943, lng: 9.25556400000005 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1343',
      zip: '70563',
      name: 'Stuttgart Vaihingen',
      street: 'Herrenberger Str. 4',
      city: 'Stuttgart',
      position: { lat: 48.7294274, lng: 9.11228589999996 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3664',
      zip: '70565',
      name: 'Stuttgart Fasanenhof',
      street: 'Europaplatz 20',
      city: 'Stuttgart',
      position: { lat: 48.7134357, lng: 9.160379199999966 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3651',
      zip: '71034',
      name: 'Böblingen',
      street: 'Wolfgang-Brumme-Allee 27',
      city: 'Böblingen',
      position: { lat: 48.6872601, lng: 9.005982700000004 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '2122',
      zip: '73033',
      name: 'Göppingen - Schulstraße',
      street: 'Schulstr. 9',
      city: 'Göppingen',
      position: { lat: 48.7029773, lng: 9.653495900000053 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1086',
      zip: '73430',
      name: 'Aalen',
      street: 'Friedhofstr. 1',
      city: 'Aalen',
      position: { lat: 48.83724220000001, lng: 10.09160780000002 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1447',
      zip: '75173',
      name: 'Pforzheim Schlössle Galerie',
      street: 'Westliche Karl Friedrich Str. 80',
      city: 'Pforzheim',
      position: { lat: 48.8920506, lng: 8.696791200000007 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1064',
      zip: '75365',
      name: 'Calw',
      street: 'Lederstr. 16-20',
      city: 'Calw',
      position: { lat: 48.7148068, lng: 8.74001629999998 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3667',
      zip: '79618',
      name: 'Rheinfelden',
      street: 'Kapuzinerstr. 2',
      city: 'Rheinfelden',
      position: { lat: 47.5587716, lng: 7.786076099999946 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1377',
      zip: '79713',
      name: 'Bad Säckingen',
      street: 'Basler Str. 1',
      city: 'Bad Säckingen',
      position: { lat: 47.55182, lng: 7.936090000000036 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1497',
      zip: '79725',
      name: 'Laufenburg',
      street: 'Laufenpark 8',
      city: 'Laufenburg',
      position: { lat: 47.5642266, lng: 8.074312100000043 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '1405',
      zip: '79761',
      name: 'Waldshut-Tiengen',
      street: 'Hauptstr. 39',
      city: 'Waldshut',
      position: { lat: 47.6353479, lng: 8.27587040000003 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3685',
      zip: '26789',
      name: 'Leer',
      street: 'Mühlenstr. 113-117',
      city: 'Leer',
      position: { lat: 53.23126, lng: 7.460450000000037 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3295',
      zip: '49716',
      name: 'Meppen',
      street: 'Hasestr. 8',
      city: 'Meppen',
      position: { lat: 52.693152, lng: 7.290921199999957 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1286',
      zip: '56068',
      name: 'Koblenz',
      street: 'Altlöhrtor 9 - 11',
      city: 'Koblenz',
      position: { lat: 50.3587383, lng: 7.593931699999985 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1359',
      zip: '56564',
      name: 'Neuwied',
      street: 'Mittelstr. 23',
      city: 'Neuwied',
      position: { lat: 50.4283821, lng: 7.45699860000002 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1457',
      zip: '75015',
      name: 'Bretten Pforzheimer Straße',
      street: 'Pforzheimer Str. 46',
      city: 'Bretten',
      position: { lat: 49.0323514, lng: 8.704340499999944 },
      region: 'DE',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3293',
      zip: '76646',
      name: 'Bruchsal',
      street: 'Anton-Heuchemer Str. 6',
      city: 'Bruchsal',
      position: { lat: 49.1241058, lng: 8.597297300000037 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1150',
      zip: '80331',
      name: 'München Tal',
      street: 'Tal 23 - 25',
      city: 'München',
      position: { lat: 48.1359237, lng: 11.580266199999983 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3262',
      zip: '80331',
      name: 'München Sonnenstraße',
      street: 'Sonnenstr. 2',
      city: 'München',
      position: { lat: 48.1383097, lng: 11.56465890000004 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1134',
      zip: '80335',
      name: 'München Elisenhof',
      street: 'Prielmayerstr. 3',
      city: 'München',
      position: { lat: 48.140981, lng: 11.561884599999985 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3604',
      zip: '81241',
      name: 'München Pasing Arcaden',
      street: 'Bahnhofsplatz 5',
      city: 'München',
      position: { lat: 48.1488843, lng: 11.461915299999987 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3730',
      zip: '81737',
      name: 'München PEP',
      street: 'Ollenhauerstr. 6',
      city: 'München',
      position: { lat: 48.1007548, lng: 11.64453560000004 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1432',
      zip: '81829',
      name: 'München Riem Arcaden',
      street: 'Willy Brandt Platz 5',
      city: 'München',
      position: { lat: 48.1318875, lng: 11.691102900000033 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1023',
      zip: '85221',
      name: 'Ottobrunn Isar Center',
      street: 'Unterhachinger Str. 28',
      city: 'Ottobrunn',
      position: { lat: 48.0632033, lng: 11.649141999999983 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3682',
      zip: '48431',
      name: 'Rheine Ems-Galerie',
      street: 'Emsstr. 36',
      city: 'Rheine',
      position: { lat: 52.2783326, lng: 7.440975799999933 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1035',
      zip: '85049',
      name: 'Ingolstadt Ludwigstraße',
      street: 'Ludwigstr. 22',
      city: 'Ingolstadt',
      position: { lat: 48.7642085, lng: 11.427012399999967 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1312',
      zip: '85057',
      name: 'Ingolstadt West Park',
      street: 'Am Westpark 6',
      city: 'Ingolstadt',
      position: { lat: 48.7736616, lng: 11.390352300000018 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '1055',
      zip: '86150',
      name: 'Augsburg Bahnhofstraße',
      street: 'Bahnhofstr. 29',
      city: 'Augsburg',
      position: { lat: 48.36565940000001, lng: 10.888631499999974 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '3673',
      zip: '86150',
      name: 'Augsburg Annastrasse',
      street: 'Annastrasse',
      city: 'Augsburg',
      position: { lat: 48.3680965, lng: 10.89603969999996 },
      region: 'DE',
      opening_hours: [],
      module: '1m'
    },
    {
      id: '5101',
      zip: '3011',
      name: 'Bern Spitalgasse 26',
      street: 'Spitalgasse 26',
      city: 'Bern',
      position: { lat: 46.9482271, lng: 7.442407199999934 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5108',
      zip: '4622',
      name: 'Egerkingen EKZ Gäupark',
      street: 'Hausimollstr. 1',
      city: 'Egerkingen',
      position: { lat: 47.3175094, lng: 7.8020477999999684 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5129',
      zip: '4058',
      name: 'Basel EKZ Clara Huus',
      street: 'Teichgässlein 7',
      city: 'Basel',
      position: { lat: 47.5620752, lng: 7.592248499999982 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5160',
      zip: '6130',
      name: 'Willisau',
      street: 'Ettiswilerstr. 25',
      city: 'Willisau',
      position: { lat: 47.1254698, lng: 7.9958904000000075 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5178',
      zip: '3600',
      name: 'Thun Freienhofgasse',
      street: 'Freienhofgasse 3',
      city: 'Thun',
      position: { lat: 46.7572511, lng: 7.631410899999992 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5180',
      zip: '5734',
      name: 'Reinach',
      street: 'Aarauerstr. 42',
      city: 'Reinach',
      position: { lat: 47.2617681, lng: 8.17759639999997 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5182',
      zip: '6030',
      name: 'Ebikon EKZ Mall of Switzerland',
      street: 'Ebisquare-Strasse 1',
      city: 'Ebikon',
      position: { lat: 47.0935442, lng: 8.358132599999976 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '3596',
      zip: '9535',
      name: 'Wilen bei Wil',
      street: 'Weidstr. 6',
      city: ' Wilen bei Wil',
      position: { lat: 47.4542424, lng: 9.039014299999963 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5125',
      zip: '8580',
      name: 'Amriswil EKZ Amriville',
      street: 'Kirchstr. 11',
      city: 'Amriswil',
      position: { lat: 47.5476347, lng: 9.297553500000049 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5141',
      zip: '8620',
      name: 'Wetzikon',
      street: 'Zürcherstr. 84',
      city: 'Wetzikon',
      position: { lat: 47.3275186, lng: 8.783677099999977 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5150',
      zip: '8752',
      name: 'Näfels',
      street: 'Oberdorf 46',
      city: 'Näfels',
      position: { lat: 47.0934256, lng: 9.059238199999982 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5157',
      zip: '8157',
      name: 'Dielsdorf EKZ Migros Center',
      street: 'Ruchwiesenstr. 2',
      city: 'Dielsdorf',
      position: { lat: 47.4804655, lng: 8.46442090000005 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5158',
      zip: '9470',
      name: 'Buchs',
      street: 'Bahnhofstr. 47/49',
      city: 'Buchs',
      position: { lat: 47.1658237, lng: 9.472725500000024 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5177',
      zip: '8604',
      name: 'Volketswil EKZ Inside',
      street: 'Hofwiesenstr. 4',
      city: 'Volketswil',
      position: { lat: 47.3816345, lng: 8.672399199999973 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5179',
      zip: '7151',
      name: 'Schluein',
      street: 'Via Isla 53',
      city: 'Schluein',
      position: { lat: 46.779028, lng: 9.218882699999995 },
      region: 'CH',
      opening_hours: [],
      module: '0,5m'
    },
    {
      id: '5707',
      zip: '3430',
      name: 'Tulln',
      street: 'Hauptplatz 12-14',
      city: 'Tulln',
      position: { lat: 48.3299951, lng: 16.050609500000064 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '1963',
      zip: '4061',
      name: 'Pasching Plus City',
      street: 'Pluskaufstr. 7',
      city: 'Pasching',
      position: { lat: 48.2435078, lng: 14.236751700000013 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5601',
      zip: '4600',
      name: 'Wels Shoppingcity',
      street: 'Salzburger Str. 223',
      city: 'Wels',
      position: { lat: 48.1451415, lng: 13.975369300000011 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5706',
      zip: '4600',
      name: 'Wels-Nord',
      street: 'Oberfeldstraße 79',
      city: 'Wels',
      position: { lat: 48.1807336, lng: 14.011696499999971 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5606',
      zip: '4863',
      name: 'Seewalchen',
      street: 'Anton Bruckner Str. 1',
      city: 'Seewalchen',
      position: { lat: 47.9551527, lng: 13.582925299999943 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5672',
      zip: '4910',
      name: 'Ried im Innkreis',
      street: 'Dr. - Franz - Berger - Str. 1',
      city: 'Ried im Innkreis',
      position: { lat: 48.2072386, lng: 13.485611800000015 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '1958',
      zip: '6020',
      name: 'Innsbruck',
      street: 'Maria Theresienstr. 18',
      city: 'Innsbruck',
      position: { lat: 47.2656919, lng: 11.393869499999937 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5622',
      zip: '6020',
      name: 'Innsbruck Einkaufszentrum West',
      street: 'Höttinger Au  73-75',
      city: 'Innsbruck',
      position: { lat: 47.2645734, lng: 11.373972099999946 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '3592',
      zip: '6130',
      name: 'Schwaz Stadt Galerien',
      street: 'Andreas Hoferstr. 10',
      city: 'Schwaz',
      position: { lat: 47.3450219, lng: 11.707764099999963 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5600',
      zip: '6300',
      name: 'Wörgl M4',
      street: 'Salzburger Str. 32',
      city: 'Wörgl',
      position: { lat: 47.4890921, lng: 12.069882499999949 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5613',
      zip: '6500',
      name: 'Landeck',
      street: 'Malser Str. 19',
      city: 'Landeck',
      position: { lat: 47.1375227, lng: 10.566271599999936 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5623',
      zip: '6600',
      name: 'Reutte',
      street: 'Mühlerstr. 11c',
      city: 'Reutte',
      position: { lat: 47.4910181, lng: 10.719718999999941 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5663',
      zip: '6706',
      name: 'Bürs FMZ',
      street: 'Almteilweg 3',
      city: 'Bürs',
      position: { lat: 47.1494011, lng: 9.8140535 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5645',
      zip: '6840',
      name: 'Götzis',
      street: 'Am Garnmarkt 12-18',
      city: 'Götzis',
      position: { lat: 47.3364846, lng: 9.647163699999965 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5704',
      zip: '4040',
      name: 'Linz Lentia',
      street: 'Blütenstr. 13-23',
      city: 'Linz',
      position: { lat: 48.314206, lng: 14.283195999999975 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5651',
      zip: '4240',
      name: 'Freistadt',
      street: 'Industriestr. 2f',
      city: 'Freistadt',
      position: { lat: 48.4975063, lng: 14.501471100000003 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '1955',
      zip: '4400',
      name: 'Steyr City Point',
      street: 'Tomitzstr /Leop.-Werndl-Str. 2',
      city: 'Steyr',
      position: { lat: 48.0373577, lng: 14.414850200000046 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5659',
      zip: '4481',
      name: 'Asten',
      street: 'Handelsring 8-10',
      city: 'Asten',
      position: { lat: 48.2210523, lng: 14.40549169999997 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5625',
      zip: '5020',
      name: 'Salzburg Forum',
      street: 'Südtiroler Platz 13',
      city: 'Salzburg',
      position: { lat: 47.8142094, lng: 13.045190899999966 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5668',
      zip: '5020',
      name: 'Salzburg SCA',
      street: 'Alpenstr. 114-120',
      city: 'Salzburg',
      position: { lat: 47.7741416, lng: 13.068308500000057 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5709',
      zip: '5500',
      name: 'Bischofshofen',
      street: 'Gasteiner Str. 46',
      city: 'Bischofshofen',
      position: { lat: 47.4110164, lng: 13.21491530000003 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5602',
      zip: '9020',
      name: 'Klagenfurt City Arkaden',
      street: 'City Arkaden',
      city: 'Klagenfurt',
      position: { lat: 46.627373, lng: 14.309868999999935 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5679',
      zip: '9020',
      name: 'Klagenfurt FMZ Kika',
      street: 'Völkermarkter Straße 163',
      city: 'Klagenfurt',
      position: { lat: 46.62751979999999, lng: 14.343593599999963 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5664',
      zip: '9300',
      name: 'St. Veit an der Glan',
      street: 'Völkermarkter Str. 36',
      city: 'St. Veit an der Glan',
      position: { lat: 46.76506630000001, lng: 14.369317799999976 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5635',
      zip: '9500',
      name: 'Villach ATRIO',
      street: 'Kärntner Str. 34',
      city: 'Villach',
      position: { lat: 46.5964359, lng: 13.83591709999996 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5684',
      zip: '9800',
      name: 'Spittal a.d. Drau',
      street: 'Villacher Straße 103',
      city: 'Spittal a.d. Drau',
      position: { lat: 46.7883924, lng: 13.520665300000019 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5611',
      zip: '1210',
      name: 'Wien Seyring',
      street: 'Seyringer Str. 12-14',
      city: 'Wien',
      position: { lat: 48.26748749999999, lng: 16.45722980000005 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5615',
      zip: '2000',
      name: 'Stockerau',
      street: 'Wiener Str. 32a',
      city: 'Stockerau',
      position: { lat: 48.38472120000001, lng: 16.23488509999993 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5652',
      zip: '2201',
      name: 'Gerasdorf G3 Shopping Resort',
      street: 'G3 Platz 1',
      city: 'Gerasdorf bei Wien',
      position: { lat: 48.3417606, lng: 16.466421699999955 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5609',
      zip: '3580',
      name: 'Horn Einkaufszentrum',
      street: 'Am Kuhberg 5',
      city: 'Horn',
      position: { lat: 48.6673123, lng: 15.635869599999978 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5648',
      zip: '3830',
      name: 'Waidhofen a.d. Thaya',
      street: 'Thayaparkstr. 1',
      city: 'Waidhofen a.d. Thaya',
      position: { lat: 48.8110272, lng: 15.272109999999998 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '1961',
      zip: '8055',
      name: 'Seiersberg',
      street: 'Seiersberg 1-9',
      city: 'Seiersberg',
      position: { lat: 47.0114363, lng: 15.408730099999957 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5618',
      zip: '8430',
      name: 'Leibnitz',
      street: 'Leopold-Figl-Str. 1',
      city: 'Leibnitz',
      position: { lat: 46.7960211, lng: 15.554637700000058 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5650',
      zip: '8530',
      name: 'Deutschlandsberg',
      street: 'Frauentaler Str. 70',
      city: 'Deutschlandsberg',
      position: { lat: 46.81848429999999, lng: 15.227545899999996 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5690',
      zip: '8572',
      name: 'Bärnbach WEZ',
      street: 'Packer Straße 18',
      city: 'Bärnbach',
      position: { lat: 47.053691, lng: 15.129967299999976 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5676',
      zip: '9100',
      name: 'Völkermarkt',
      street: 'Umfahrungsstr. 22',
      city: 'Völkermarkt',
      position: { lat: 46.662491, lng: 14.63159070000006 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '1959',
      zip: '9400',
      name: 'Wolfsberg Am Weiher',
      street: 'Am Weiher 11',
      city: 'Wolfsberg',
      position: { lat: 46.8405587, lng: 14.84159610000006 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5677',
      zip: '9400',
      name: 'Wolfsberg Klagenfurter Strasse',
      street: 'Klagenfurter Str. 41',
      city: 'Wolfsberg',
      position: { lat: 46.8221168, lng: 14.839102000000025 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '3595',
      zip: '1210',
      name: 'Wien C21',
      street: 'Ernst-Vasovec-Gasse 14',
      city: 'Wien',
      position: { lat: 48.296601, lng: 16.37678600000004 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5614',
      zip: '1210',
      name: 'Wien Floridsdorf',
      street: 'Franz-Jonas-Platz 2-3',
      city: 'Wien',
      position: { lat: 48.2564406, lng: 16.39826400000004 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5685',
      zip: '1210',
      name: 'Wien SCN-Shopping Center Nord',
      street: 'Ignaz-Köck-Str 1',
      city: 'Wien',
      position: { lat: 48.2664657, lng: 16.398233000000005 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5610',
      zip: '1220',
      name: 'Wien Stadlau',
      street: 'Gewerbeparkstr. 10',
      city: 'Wien',
      position: { lat: 48.246208, lng: 16.46200429999999 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5644',
      zip: '1220',
      name: 'Wien Donau Zentrum',
      street: 'Wagramer Str. 94 Top 54',
      city: 'Wien',
      position: { lat: 48.2420786, lng: 16.43594630000007 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5616',
      zip: '7400',
      name: 'Oberwart eo Einkaufszentrum',
      street: 'Europa Str. 1',
      city: 'Oberwart',
      position: { lat: 47.2868726, lng: 16.187817999999993 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5603',
      zip: '8020',
      name: 'Graz Citypark',
      street: 'Lazarettgürtel 55',
      city: 'Graz',
      position: { lat: 47.0602741, lng: 15.427053999999998 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5678',
      zip: '8051',
      name: 'Graz Gösting',
      street: 'Wiener Str. 333',
      city: 'Graz',
      position: { lat: 47.1030939, lng: 15.400831499999981 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5680',
      zip: '8230',
      name: 'Hartberg FMZ Hatric',
      street: 'Im Hatric 12',
      city: 'Hartberg',
      position: { lat: 47.2807216, lng: 15.99463019999996 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5628',
      zip: '8280',
      name: 'Fürstenfeld',
      street: 'Grazer Str. 16',
      city: 'Fürstenfeld',
      position: { lat: 47.05980779999999, lng: 16.06792280000002 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5687',
      zip: '8330',
      name: 'Feldbach',
      street: 'Gewerbepark 10',
      city: 'Feldbach',
      position: { lat: 46.9498437, lng: 15.915185299999962 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '1954',
      zip: '1060',
      name: 'Wien Mariahilfer Straße',
      street: 'Mariahilfer Str. 97',
      city: 'Wien',
      position: { lat: 48.1967463, lng: 16.346443499999964 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5604',
      zip: '1100',
      name: 'Wien Favoriten',
      street: 'Favoritenstr. 112-114',
      city: 'Wien',
      position: { lat: 48.1776799, lng: 16.37656340000001 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '1962',
      zip: '1120',
      name: 'Wien Meidling',
      street: 'Philadelphiabrücke  / Wilhelm Str. 66+68',
      city: 'Wien',
      position: { lat: 48.1749015, lng: 16.331082599999945 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5657',
      zip: '1140',
      name: 'Wien Auhof Center',
      street: 'Albert-Schweitzer-Gasse 6',
      city: 'Wien',
      position: { lat: 48.2072168, lng: 16.223902299999963 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5643',
      zip: '1150',
      name: 'Wien Bahnhof City Wien West',
      street: 'Europaplatz 2',
      city: 'Wien',
      position: { lat: 48.19671899999999, lng: 16.337598200000002 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5660',
      zip: '1150',
      name: 'Wien Lugner City',
      street: 'Gablenzgasse 11',
      city: 'Wien',
      position: { lat: 48.2039397, lng: 16.334497899999974 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5605',
      zip: '7000',
      name: 'Eisenstadt',
      street: 'Johann-Pack-Str. 4',
      city: 'Eisenstadt',
      position: { lat: 47.8257664, lng: 16.53078579999999 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5654',
      zip: '8700',
      name: 'Leoben',
      street: 'Hauptplatz 17',
      city: 'Leoben',
      position: { lat: 47.3815152, lng: 15.09390940000003 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5617',
      zip: '8753',
      name: 'Fohnsdorf',
      street: 'Arena am Waldfeld 26',
      city: 'Fohnsdorf',
      position: { lat: 47.1846202, lng: 14.686296699999957 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5621',
      zip: '8940',
      name: 'Liezen',
      street: 'Gesäusestr. 3',
      city: 'Liezen',
      position: { lat: 47.560882, lng: 14.248569399999951 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5607',
      zip: '1020',
      name: 'Wien Stadion Center',
      street: 'Olympiaplatz 1',
      city: 'Wien',
      position: { lat: 48.2081594, lng: 16.420552499999985 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5629',
      zip: '1030',
      name: 'Wien Galleria Einkaufszentrum',
      street: 'Landstraßer Hauptstr. 99-101',
      city: 'Wien',
      position: { lat: 48.1985713, lng: 16.39486450000004 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5656',
      zip: '1030',
      name: 'Wien Mitte',
      street: 'Landstrasser Hauptstraße 1B',
      city: 'Wien',
      position: { lat: 48.2069471, lng: 16.385245899999973 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5669',
      zip: '1100',
      name: 'Wien Hauptbahnhof',
      street: 'Am Hauptbahnhof 1  Top EG 138',
      city: 'Wien',
      position: { lat: 48.18512500000001, lng: 16.376081999999997 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5694',
      zip: '1100',
      name: 'Wien Triester Strasse',
      street: 'Triester Straße 40',
      city: 'Wien',
      position: { lat: 48.1769973, lng: 16.35628900000006 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5636',
      zip: '1110',
      name: 'Wien Zentrum Simmering',
      street: 'Simmeringer Hauptstr. 96a',
      city: 'Wien',
      position: { lat: 48.1737928, lng: 16.41649240000004 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5692',
      zip: '1110',
      name: 'Wien Huma',
      street: 'Landwehrstr. 6',
      city: 'Wien',
      position: { lat: 48.1689364, lng: 16.472362100000055 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5655',
      zip: '2334',
      name: 'Vösendorf Shopping City Süd',
      street: 'Shopping City Süd / Galerie 251 / Eingang Müller',
      city: 'Vösendorf',
      position: { lat: 48.1083307, lng: 16.318347300000028 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5608',
      zip: '2460',
      name: 'Bruck/Leitha',
      street: 'Eco-Plus-Park 4.Straße Top 15',
      city: 'Bruck/Leitha',
      position: { lat: 48.02375, lng: 16.77534000000003 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5619',
      zip: '2512',
      name: 'Oeynhausen',
      street: 'Triester Str. 72',
      city: 'Oeynhausen',
      position: { lat: 47.9847881, lng: 16.290574500000048 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5681',
      zip: '2620',
      name: 'Neunkirchen Panoramapark',
      street: 'Schraubenwerkstr. 1',
      city: 'Neunkirchen',
      position: { lat: 47.72347389999999, lng: 16.079717699999946 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5670',
      zip: '2700',
      name: 'Wiener Neustadt Merkurcity',
      street: 'Stadionstr. 10-12',
      city: 'Wiener Neustadt',
      position: { lat: 47.8238674, lng: 16.25721920000001 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '5667',
      zip: '3100',
      name: 'St. Poelten',
      street: 'Brunngasse 3+5',
      city: 'St. Poelten',
      position: { lat: 48.2065549, lng: 15.624101500000052 },
      region: 'AT',
      module: '1m'
    },
    {
      id: '1956',
      zip: '3300',
      name: 'Amstetten City Center',
      street: 'Waidhofner Str. 2',
      city: 'Amstetten',
      position: { lat: 48.1220909, lng: 14.869230499999958 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5646',
      zip: '3370',
      name: 'Ybbs',
      street: 'Bahnhofstr. 11',
      city: 'Ybbs',
      position: { lat: 48.1726506, lng: 15.085343299999977 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5649',
      zip: '3390',
      name: 'Melk',
      street: 'Löwenpark 1',
      city: 'Melk',
      position: { lat: 48.2244942, lng: 15.337606100000016 },
      region: 'AT',
      module: '0,5m'
    },
    {
      id: '5674',
      zip: '7111',
      name: 'Parndorf Burgenland ',
      street: 'Richard-Erlinger-Platz 1',
      city: ' Parndorf TOP 8',
      position: { lat: 47.97511, lng: 16.85413140000003 },
      region: 'AT',
      module: '0,5m'
    }
  ]
};

export default markersMock;
