import React from 'react';
import styles from '../../CmsMap.module.scss';

const InfoWindowContent = ({ labels, name, street, zip, city, module }) => {
  return (
    <div className={styles.CmsMapMarkerContainer}>
      <div className={styles.CmsMapMarkerOverlay}>
        <h4>{name}</h4>
        <p>
          <strong>{labels.street}:</strong> {street}
        </p>
        <p>
          <strong>{labels.city}:</strong> {zip}, {city}
        </p>
        <p>
          <strong>{labels.module}:</strong> {module}
        </p>
      </div>
    </div>
  );
};

export default InfoWindowContent;
